<template>
    <div>
        <nav-bar title="联系我们" url="/"></nav-bar>
        <div class="contact-top">
            <img :src="info.bg">
        </div>

        <div class="top">
            <div class="title">
                <div class="title-text">联系我们</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="contact-content" v-html="info.content"></div>
        </div>



    </div>
</template>

<script>
    import {
        common_contact
    } from '@/api/common.js';
    export default {
        name: 'contact',
        data() {
            return {
                info: {},
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                common_contact({}).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .contact-top img {
        width: 100%;
    }

    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        border: 1px solid var(--main-color);
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .contact-content {
        padding: 15px;
        color: var(--main-color);
    }

    /deep/ .contact-content img {
        max-width: 100%;
    }
</style>